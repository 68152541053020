import { useMemo } from 'react'

import { costsViewModel } from './utils'
import { InputState } from '../input/types'
import { usePricingData } from '../pricingDataProvider'

export function useResults(inputState: InputState) {
  const data = usePricingData()

  const results = useMemo(
     () => costsViewModel({ data, ...inputState }), 
     [data, inputState]
  )

  return results

}
