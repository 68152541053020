import React, { FC } from 'react'

import { Text } from 'mf-ui-components'

import { ResultsRowProps } from './types'
import { formatMonthlyValue, formatYearlyValue } from './utils'
import {
  PartialResultsContainer,
  ResultValueContainer,
  SubtotalValue,
} from './Results.styles'
import { CalculatedAppliedCost } from '../types'

type Props = {
  label: string
  fees: CalculatedAppliedCost
} & ResultsRowProps

export const SubTotalResultsRow: FC<Props> = ({
  label,
  fees: { monthly, yearly },
  displayMode,
  pricingView,
}) => (
  <PartialResultsContainer>
    <div>
      <Text el="span" styleName="label">
        {label}
      </Text>
    </div>

    {displayMode === 'desktop' ? (
      <>
        <ResultValueContainer>
          <SubtotalValue>{formatYearlyValue(yearly)}</SubtotalValue>
        </ResultValueContainer>
        <ResultValueContainer>
          <SubtotalValue>{formatMonthlyValue(monthly)}</SubtotalValue>
        </ResultValueContainer>
      </>
    ) : (
      <ResultValueContainer>
        <SubtotalValue>
          {pricingView === 'yearly'
            ? formatYearlyValue(yearly)
            : formatMonthlyValue(monthly)}
        </SubtotalValue>
      </ResultValueContainer>
    )}
  </PartialResultsContainer>
)
