import React, { FC } from 'react'

import { FeeBracketsTable, Grid, Text } from 'mf-ui-components'

import { getInvestmentFundFees } from './utils'
import { Product } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { usePricingData } from '../pricingDataProvider'

type Props = {
  product: Product
  strategy: string
  focus: string
}

export const InvestmentFundFeesTable: FC<Props> = ({
  product,
  strategy,
  focus,
}) => {
  const pricingData = usePricingData()
  const data = getInvestmentFundFees(pricingData, product, strategy, focus)

  if (!data) return null

  return (
    <Grid flow="column" gap="2xs">
      <Text styleName="label" el="p">
        {l10nCopy(
          'multiProductPricingCalculator.feesModal.investmentFundFees.sectionTitle'
        )}
      </Text>
      <FeeBracketsTable
        title={l10nCopy(
          'multiProductPricingCalculator.feesModal.investmentFundFees.tableTitle'
        )}
        tableData={data}
        margin={0}
      />
    </Grid>
  )
}
