import { formatMonthlyValue, formatYearlyValue } from '../results/utils'
import { Product, PercentageCost, PricingData } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { currency, locale } from '../../../../../utils/env'

export function getManagementFees(
  data: PricingData,
  product: Product,
  strategy: string
) {
  const key = product === 'pip' ? 'pip' : strategy

  if (!(key in data.managementFees)) return null

  const fees = data.managementFees[key]

  if (fees.type === 'tiered')
    return Object.values(fees.tiers)
      .filter(({ value }) => value > 0)
      .map(value => ({
        description: `${l10nCopy(
          'multiProductPricingCalculator.generic.from'
        )} ${Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
        }).format(value.from)}`,
        actualValue: formatYearlyValue(value.value),
      }))

  if (fees.type === 'percentage')
    return [
      {
        description: `${l10nCopy(
          'multiProductPricingCalculator.generic.from'
        )} ${formatMonthlyValue(data.products.find((datum) => datum.name === product)?.minimumInvestment ?? data.minimumInvestment)}`,
        actualValue: formatYearlyValue(fees.value),
      },
    ]

  if (fees.type === 'life-insurance')
    return [
      {
        description: `${l10nCopy(
          'multiProductPricingCalculator.gestioneSeparataFees'
        )} (${fees.gestioneSeparataWeight * 100}%)`,
        actualValue: formatYearlyValue(fees.gestioneSeparataValue),
      },
      {
        description: l10nCopy('multiProductPricingCalculator.unitLinkedFees'),
        actualValue: formatYearlyValue(fees.unitValue),
      },
      {
        description: l10nCopy('generic.total'),
        actualValue: formatYearlyValue(
          fees.gestioneSeparataValue + fees.unitValue
        ),
      },
    ]

  return null
}

export function getInvestmentFundFees(
  data: PricingData,
  product: Product,
  strategy: string,
  focus: string
) {
  // Check if the product can have an ESG focus variant.
  if (
    product === 'gia' ||
    product === 'sipp' ||
    product === 'isa' ||
    product === 'jisa'
  ) {
    const esgKey = strategy + (focus === 'esg' ? '-esg' : '')

    return Object.entries({
      active: (data.investmentFundFees[strategy] as PercentageCost).value,
      esg: (data.investmentFundFees[esgKey] as PercentageCost).value,
      thematics: (data.investmentFundFees.thematics as PercentageCost).value,
    }).map(([key, value]) => ({
      description: l10nCopy(
        `multiProductPricingCalculator.feesModal.investmentFundFees.${key}`
      ),
      actualValue: formatYearlyValue(value),
    }))
  }

  if (product === 'sicura-e-dinamica')
    return [
      {
        description: ' ',
        actualValue: `${l10nCopy(
          'multiProductPricingCalculator.generic.upTo'
        )} ${formatYearlyValue(
          (data.investmentFundFees['sicura-e-dinamica'] as PercentageCost).value
        )}`,
      },
    ]

  return null
}

export function getMarketSpreadFees(
  data: PricingData,
  product: Product,
  strategy: string,
  focus: string,
  isThematics: boolean
): string {
  if (isThematics)
    return formatYearlyValue(
      (data.marketSpreadFees.thematics as PercentageCost).value
    )

  if (product === 'sicura-e-dinamica')
    return formatYearlyValue(
      (data.marketSpreadFees['sicura-e-dinamica'] as PercentageCost).value
    )

  if (focus === 'esg')
    return formatYearlyValue(
      (data.marketSpreadFees[`${strategy}-esg`] as PercentageCost).value
    )

  return formatYearlyValue(
    (data.marketSpreadFees[strategy] as PercentageCost).value
  )
}
