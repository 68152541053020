
import React, { FC } from 'react'

import { PartialResultsRow } from './PartialResultsRow'
import { CostsViewModel, ResultsRowProps } from './types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

type Props = {
  fees: CostsViewModel['financialInstrumentsFees']
} & ResultsRowProps

export const FinancialInstrumentsFees: FC<Props> = ({
  fees,
  ...resultsRowProps
}) => (
  <>
    {(['investmentFundFees', 'marketSpreadFees'] as const).map(
      key =>
        fees[key] !== null && (
          <PartialResultsRow
            key={key}
            label={l10nCopy(`multiProductPricingCalculator.${key}`)}
            modalText={l10nCopy(`multiProductPricingCalculator.${key}Tooltip`)}
            fees={fees[key]!}
            {...resultsRowProps}
          />
        )
    )}
  </>
)
