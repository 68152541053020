import { Dispatch } from 'react'

import { EVENTS } from './constants'
import {
  getAvailableAvailableStrategiesWithThematics,
  getAvailableFocuses,
  getAvailableStrategies,
} from './utils'
import { Action, InputState } from './types'
import { PricingData, Product } from '../types'

export const actionsFactory = (
  data: PricingData,
  state: InputState,
  dispatch: Dispatch<Action>
) => ({
  /**
   * Updates the amount to invest. If the new amount is less than the minimum
   * amount to invest, the minimum amount is set.
   */
  setAmountToInvest: (newAmount: number) => {
    const amountToSet = Math.max(newAmount, data.minimumInvestment)

    return dispatch({
      type: EVENTS.SET_AMOUNT_TO_INVEST,
      payload: { amountToInvest: amountToSet },
    })
  },

  /**
   * Updates the selected product. If the strategy cannot be kept, the first
   * available strategy is selected. If the focus cannot be kept, the first
   * available focus is selected.
   */
  setProduct: (newProduct: Product) => {
    const availableStrategies = getAvailableStrategies(data, newProduct)
    const canKeepStrategy = availableStrategies.some(
      strategy => strategy === state.strategy
    )
    const strategy = canKeepStrategy ? state.strategy : availableStrategies[0]

    const availableFocuses = getAvailableFocuses(data, newProduct, strategy)
    const canKeepFocus = availableFocuses.some(focus => focus === state.focus)
    const focus = canKeepFocus ? state.focus : availableFocuses[0]

    const availableStrategiesWithThematics = getAvailableAvailableStrategiesWithThematics(
      data,
      newProduct
    )
    const canKeepThematics = availableStrategiesWithThematics.some(
      strategy => strategy === state.strategy
    )
    const isThematics = canKeepThematics ? state.isThematics : false

    return dispatch({
      type: EVENTS.SET_PRODUCT,
      payload: {
        product: newProduct,
        strategy,
        focus,
        availableStrategies,
        availableFocuses,
        isThematics,
      },
    })
  },

  /**
   * Updates the selected strategy. If the focus cannot be kept, the first
   * available focus is selected.
   */
  setStrategy: (newStrategy: string) => {
    const availableFocuses = getAvailableFocuses(
      data,
      state.product,
      newStrategy
    )
    const canKeepFocus = availableFocuses.some(name => name === state.focus)
    const focus = canKeepFocus ? state.focus : availableFocuses[0]

    const availableStrategiesWithThematics = getAvailableAvailableStrategiesWithThematics(
      data,
      state.product
    )
    const canKeepThematics = availableStrategiesWithThematics.some(
      strategy => strategy === newStrategy
    )
    const isThematics = canKeepThematics ? state.isThematics : false

    return dispatch({
      type: EVENTS.SET_STRATEGY,
      payload: { strategy: newStrategy, focus, availableFocuses, isThematics },
    })
  },

  /**
   * Updates the selected focus.
   */
  setFocus: (newFocus: string) => {
    return dispatch({ type: EVENTS.SET_FOCUS, payload: { focus: newFocus } })
  },

  /**
   * Toggles the thematics option.
   */
  setThematics: (isThematics: boolean) => {
    return dispatch({
      type: EVENTS.SET_THEMATICS,
      payload: { isThematics },
    })
  },
})
