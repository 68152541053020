import styled from 'styled-components'

import { Grid } from 'mf-ui-components'

import { fromBreakpoint } from '../../../../../utils/style/breakpoint'
import { spacing } from '../../../../../utils/ui/spacing'

export const Container = styled(Grid).attrs({
  flow: 'column',
  gap: 'xl',
})`
  padding: ${spacing('xl')};
  background-color: ${({ theme }) => theme.tokens.space.tertiary.gradient0};
  border-radius: 8px;
  height: fit-content;

  ${fromBreakpoint('md')} {
    width: 360px;
  }
`

export const EditableInputField = styled.input`
  font-size: 38px;
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  background: none;
  border: none;
  outline: none;
  font-family: 'ivypresto-display', 'Playfair Display', serif;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  padding: 0;
  line-height: 1.25;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
