import React, { FC } from 'react'

import { FeeBracketsTable } from 'mf-ui-components'

import { useInputState } from '../input/inputStateReducer'
import { usePricingData } from '../pricingDataProvider'
import { formatMonthlyValue, getAdditionalFees } from '../results/utils'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

export const AdditionalCostsTable: FC = () => {
  const pricingData = usePricingData()
  const {
    state: { amountToInvest, product },
  } = useInputState()

  // For now PIP is the only product with additional fees.
  if (product !== 'pip') return null

  const data = getAdditionalFees(pricingData, product, amountToInvest)

  return (
    <FeeBracketsTable
      title={l10nCopy(
        'multiProductPricingCalculator.results.pipAdministrativeCosts'
      )}
      tableData={[
        {
          description: ' ',
          actualValue:
            formatMonthlyValue(data?.yearly ?? 0) +
            '/' +
            l10nCopy('generic.year'),
        },
      ]}
    />
  )
}
