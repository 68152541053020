import React, { FC } from 'react'

import { PartialResultsRow } from './PartialResultsRow'
import { LifeInsuranceFees, ResultsRowProps } from './types'
import { Product, CalculatedAppliedCost } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

type Props = {
  fees: (CalculatedAppliedCost & LifeInsuranceFees)
  product: Product
} & ResultsRowProps

export const LifeInsuranceManagementFees: FC<Props> = ({
  fees,
  product,
  displayMode,
  pricingView,
}) => (
  <>
    {/* 
      Gestione separata fees. For Sicura E Dinamica we want to show what's
      the weight for the calculated fees.
    */}
    <PartialResultsRow
      label={[
        product === 'sicura-e-dinamica' &&
          `${Number(fees.gestioneSeparataWeight * 100)}%`,
        l10nCopy('multiProductPricingCalculator.gestioneSeparataFees'),
      ]
        .filter(Boolean)
        .join(' ')}
      fees={fees.gestioneSeparataFees}
      displayMode={displayMode}
      pricingView={pricingView}
    />

    {/* 
      Unit linked fees. For Sicura100 we don't need to show this information,
      as unit linked fees amount to 0.
     */}
    {product === 'sicura-e-dinamica' && (
      <PartialResultsRow
        label={l10nCopy('multiProductPricingCalculator.unitLinkedFees')}
        fees={fees.unitLinkedFees}
        displayMode={displayMode}
        pricingView={pricingView}
      />
    )}
  </>
)
