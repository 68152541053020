import styled from 'styled-components'

import { Text } from 'mf-ui-components'

import { serifFont } from '../../../../../utils/style/serifFont'

export const Title = styled(Text).attrs({
  el: 'h2',
  styleName: 'title2',
  id: 'results-card-title',
})`
  ${serifFont}
`
