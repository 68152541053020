import React, { FC } from 'react'

import {
  ChipGroup,
  Grid,
  Select,
  Switch,
  Text,
} from 'mf-ui-components'

import { AmountInput } from './AmountInput'
import { Container } from './Input.styles'
import { useInputState } from './inputStateReducer'
import { FOCUS_OPTIONS } from '../data'
import { productHasThematicsOption } from '../results/utils'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

export const Input: FC = () => {
  const { state, actions } = useInputState()

  return (
    <Container>
      <Grid flow="column" gap={null}>
        <Text el="span" styleName="label">
          {l10nCopy('multiProductPricingCalculator.amountToInvest')}
        </Text>
        <AmountInput />
      </Grid>

      {state.availableProducts.length > 1 && (
        <Grid flow="column" gap={null}>
          <Text el="span" styleName="label">
            {l10nCopy('multiProductPricingCalculator.investmentProduct')}
          </Text>
          <Select
            options={state.availableProducts.map(product => ({
              label: l10nCopy(`multiProductPricingCalculator.products.${product}`),
              value: product,
            }))}
            value={state.product}
            onChange={e => actions.setProduct(e.target.value)}
            visibleItems={state.availableProducts.length} // Show all options.
          />
        </Grid>
      )}

      {state.availableStrategies.length <= 1 ? null : state.product !==
          'sicura-100' && state.product !== 'sicura-e-dinamica' ? (
        <Grid flow="column" gap="2xs">
          <Text el="span" styleName="label">
            {l10nCopy('multiProductPricingCalculator.managementStyle')}
          </Text>
          <ChipGroup
            chipList={state.availableStrategies.map(name => ({
              label: l10nCopy(`multiProductPricingCalculator.strategies.${name}`),
              onClick: () => actions.setStrategy(name),
              isSelected: state.strategy === name,
            }))}
          />
        </Grid>
      ) : (
        <Grid flow="column" gap="2xs">
          <Text el="span" styleName="label">
            {l10nCopy('multiProductPricingCalculator.investmentLine')}
          </Text>
          <Select
            options={
              state.availableStrategies.map(name => ({
                label: l10nCopy(`multiProductPricingCalculator.strategies.${name}`),
                value: name,
              })) ?? []
            }
            value={state.strategy}
            onChange={e => actions.setStrategy(e.target.value)}
            visibleItems={state.availableStrategies.length}
          />
        </Grid>
      )}

      {state.availableFocuses.length > 1 && (
        <div>
          <Grid flow="column" gap="2xs">
            <Text el="span" styleName="label">
              {l10nCopy('multiProductPricingCalculator.focus')}
            </Text>
            <ChipGroup
              chipList={FOCUS_OPTIONS.filter(({ name }) =>
                state.availableFocuses.includes(name)
              ).map(({ name, icon }) => ({
                label: l10nCopy(`multiProductPricingCalculator.focuses.${name}`),
                onClick: () => actions.setFocus(name),
                isSelected: state.focus === name,
                icon: icon,
              }))}
            />
          </Grid>
        </div>
      )}

      {productHasThematicsOption(state.product, state.strategy) && (
        <Grid flow="row" justifyContent="space-between">
          <Text el="span" styleName="label">
            {l10nCopy('multiProductPricingCalculator.thematicInvestmentOption')}
          </Text>
          <Switch
            checked={state.isThematics}
            onChange={() => actions.setThematics(!state.isThematics)}
          />
        </Grid>
      )}
    </Container>
  )
}
