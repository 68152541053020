import React, { FC } from 'react'

import { Badge, Grid, Table } from 'mf-ui-components'

import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

// Needed as an empty title would cause the table to not render any cell.
const PLACEHOLDER_VALUE = ' '

export const FreeFeesSection: FC = () => (
  <>
    <Table
      headers={[
        {
          title: l10nCopy(
            'multiProductPricingCalculator.feesModal.oneOffFees.tableTitle'
          ),
          field: 'label',
        },
        {
          title: PLACEHOLDER_VALUE,
          render: () => (
            <Grid justifyContent="end">
              <Badge rounded variant="positive">
                {l10nCopy('multiProductPricingCalculator.generic.free')}
              </Badge>
            </Grid>
          ),
          field: 'value',
        },
      ]}
      data={[
        {
          label: l10nCopy(
            'multiProductPricingCalculator.feesModal.oneOffFees.content'
          ),
          value: PLACEHOLDER_VALUE,
        },
      ]}
    />

    <Table
      headers={[
        {
          title: l10nCopy(
            'multiProductPricingCalculator.feesModal.ancillaryServicesFees.tableTitle'
          ),
          field: 'label',
        },
        {
          title: PLACEHOLDER_VALUE,
          render: () => (
            <Grid justifyContent="end">
              <Badge rounded variant="positive">
                {l10nCopy('multiProductPricingCalculator.generic.free')}
              </Badge>
            </Grid>
          ),
          field: 'value',
        },
      ]}
      data={[
        {
          label: l10nCopy(
            'multiProductPricingCalculator.feesModal.ancillaryServicesFees.content'
          ),
          value: PLACEHOLDER_VALUE,
        },
      ]}
    />

    <Table
      headers={[
        {
          title: l10nCopy(
            'multiProductPricingCalculator.feesModal.incidentalCosts.tableTitle'
          ),
          field: 'label',
        },
        {
          title: PLACEHOLDER_VALUE,
          render: () => (
            <Grid justifyContent="end">
              <Badge rounded variant="positive">
                {l10nCopy('multiProductPricingCalculator.generic.free')}
              </Badge>
            </Grid>
          ),
          field: 'value',
        },
      ]}
      data={[
        {
          label: l10nCopy(
            'multiProductPricingCalculator.feesModal.incidentalCosts.content'
          ),
          value: PLACEHOLDER_VALUE,
        },
      ]}
    />
  </>
)
