import React, { FC } from 'react'

import { Grid } from 'mf-ui-components'

import { Footer } from './Footer'
import { Input } from './input/Input'
import { InputStateProvider } from './input/inputStateReducer'
import { EnabledProduct } from './input/types'
import { PricingDataProvider } from './pricingDataProvider'
import { Results } from './results/Results'
import { Container } from './styles'

type Props = { product?: EnabledProduct, initialAmount?: number }

export const MultiProductPricingCalculator: FC<Props> = ({
  product = 'all',
  initialAmount
}) => (
  <section style={{ width: '100%' }}>
    <PricingDataProvider>
      <InputStateProvider enabledProduct={product} initialAmount={initialAmount}>
        <Container>
          <Input />

          <Grid flow="column" gap="xl">
            <Results />
            <Footer />
          </Grid>
        </Container>
      </InputStateProvider>
    </PricingDataProvider>
  </section>
)
