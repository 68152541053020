import { EnabledProduct, InputState } from './types'
import { PricingData, Product } from '../types'

/**
 * Given the enabled products, returns the subset of available products from the
 * list of available products.
 */
export function getAvailableProducts(
  data: PricingData,
  enabledProduct: EnabledProduct
): Product[] {
  // If the enabled product is named 'all', return all the products.
  if (enabledProduct === 'all') return data.products.map(({ name }) => name)

  // If the enabled product is named 'life-insurance', return only the life
  // insurance products.
  if (enabledProduct === 'life-insurance')
    return data.products
      .filter(
        ({ name }) => name === 'sicura-e-dinamica' || name === 'sicura-100'
      )
      .map(({ name }) => name)

  // Otherwise, return only the enabled product.
  return data.products
    .filter(({ name }) => enabledProduct === name)
    .map(({ name }) => name)
}

/**
 * Returns the available strategies given the current selected product.
 */
export function getAvailableStrategies(
  data: PricingData,
  product: Product
): string[] {
  return (
    data.products
      .find(({ name }) => name === product)
      ?.strategies.map(({ name }) => name) ?? []
  )
}

/**
 * Returns the available focuses given the current selected product and
 * strategy.
 */
export function getAvailableFocuses(
  data: PricingData,
  product: Product,
  strategy: string
): string[] {
  return (
    data.products
      .find(({ name }) => name === product)
      ?.strategies?.find(({ name }) => name === strategy)?.variants ?? []
  )
}

/**
 * Returns the available strategies which support the thematics option given the
 * current selected product.
 */
export function getAvailableAvailableStrategiesWithThematics(
  data: PricingData,
  product: Product
): string[] {
  return (
    data.products
      .find(({ name }) => name === product)
      ?.strategies.filter(strategy => strategy.hasThematics)
      .map(({ name }) => name) ?? []
  )
}

/**
 * Builds the default state for the input.
 */
export function defaultStateFactory({
  data,
  enabledProduct,
  initialAmount,
}: {
  data: PricingData
  enabledProduct: EnabledProduct
  initialAmount?: number
}): InputState {
  const defaultProduct =
    enabledProduct === 'all'
      ? 'gia'
      : enabledProduct === 'life-insurance'
      ? 'sicura-e-dinamica'
      : enabledProduct

  const availableStrategies = getAvailableStrategies(data, defaultProduct)
  const defaultStrategy = availableStrategies[0]

  const availableFocuses = getAvailableFocuses(
    data,
    defaultProduct,
    defaultStrategy
  )

  return {
    amountToInvest: initialAmount || data.minimumInvestment,
    product: defaultProduct,
    strategy: defaultStrategy,
    focus: availableFocuses[0],
    isThematics: false,
    availableProducts: getAvailableProducts(data, enabledProduct),
    availableStrategies,
    availableFocuses,
  } as const
}
