import React, { FC, Dispatch, SetStateAction } from 'react'

import { Grid, Modal } from 'mf-ui-components'

import { FreeFeesSection } from './FreeFeesSection'
import { InvestmentFundFeesTable } from './InvestmentFundFeesTable'
import { ManagementFeesTable } from './ManagementFeesTable'
import { MarketSpreadFeesTable } from './MarketSpreadFeesTable'
import { Product } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { AdditionalCostsTable } from './AdditionalCostsTable'

type Props = {
  product: Product
  strategy: string
  focus: string
  isThematics: boolean
  isVisible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

export const FeesModal: FC<Props> = ({
  product,
  strategy = 'active',
  focus = 'classic',
  isThematics = false,
  isVisible,
  setVisible,
}) => (
  <Modal
    size="large"
    isVisible={isVisible}
    onClick={() => setVisible(false)}
    modalContentList={[
      {
        header: {
          title: l10nCopy('multiProductPricingCalculator.feesModal.title'),
          closeAction: () => setVisible(false),
        },
        body: (
          <Grid flow="column" gap="xl">
            <ManagementFeesTable product={product} strategy={strategy} />

            <AdditionalCostsTable />

            <Grid flow="column" gap="sm">
              <InvestmentFundFeesTable
                product={product}
                strategy={strategy}
                focus={focus}
              />

              <MarketSpreadFeesTable
                product={product}
                strategy={strategy}
                focus={focus}
                isThematics={isThematics}
              />
            </Grid>

            <FreeFeesSection />
          </Grid>
        ),
      },
    ]}
  />
)
