import React, { FC, useEffect, useState } from 'react'

import { Grid, Icon, Text } from 'mf-ui-components'

import { EditableInputField } from './Input.styles'
import { useInputState } from './inputStateReducer'
import { currency, locale } from '../../../../../utils/env'

export const AmountInput: FC = () => {
  const { state, actions } = useInputState()

  const [isEditing, setEditing] = useState<boolean>(false)
  const [amountToInvest, setAmountToInvest] = useState<number>(
    state.amountToInvest
  )

  useEffect(() => {
    setAmountToInvest(state.amountToInvest)
  }, [state.amountToInvest])

  if (isEditing)
    return (
      <div style={{ position: 'relative' }}>
        <Grid flow="row" alignItems="center" gap={null}>
          <EditableInputField
            autoFocus
            onFocus={e => e.target.select()}
            type="number"
            value={amountToInvest}
            onChange={e => setAmountToInvest(parseInt(e.target.value))}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setEditing(false)
                actions.setAmountToInvest(amountToInvest)
              }

              if (e.key === 'Escape') setEditing(false)
            }}
            onBlur={() => {
              setEditing(false)
              actions.setAmountToInvest(amountToInvest)
            }}
          />
        </Grid>
      </div>
    )

  return (
    <Grid
      flow="row"
      gap="sm"
      alignItems="center"
      style={{ cursor: 'text' }}
      onClick={() => setEditing(true)}
    >
      <Text
        el="span"
        styleName="title2"
        style={{
          fontFamily: "'ivypresto-display', 'Playfair Display', serif",
          textDecoration: 'underline',
        }}
      >
        {Intl.NumberFormat(locale, {
          currency,
          style: 'currency',
        }).format(state.amountToInvest)}
      </Text>
      <Icon size={16} type="icon-edit" />
    </Grid>
  )
}
