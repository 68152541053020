import { CostsViewModel, CostsViewModelInput, LifeInsuranceFees } from './types'
import {
  CalculatedAppliedCost,
  Cost,
  Product,
  PricingData,
  LifeInsuranceCost,
} from '../types'
import { currency, locale } from '../../../../../utils/env'

/**
 * Given a cost and the invested amount, calculates the applied costs on a
 * monthly (flat amount in currency) and yearly (percentage) basis.
 *
 * @param investedAmount {number} - The amount of money invested.
 * @param cost {Cost} - The cost to calculate.
 * @returns {CalculatedAppliedCost} The calculated applied cost.
 */
export function calculateCost(
  investedAmount: number,
  cost: Cost
): CalculatedAppliedCost | (CalculatedAppliedCost & LifeInsuranceFees) {
  switch (cost.type) {
    case 'fixed':
      if (cost.interval !== 'year') throw new Error('Invalid interval')

      return { monthly: cost.value / 12, yearly: cost.value }

    case 'percentage':
      return {
        monthly: ((investedAmount / 100) * cost.value) / 12,
        yearly: cost.value,
      }

    case 'tiered':
      const tier = cost.tiers.find(
        ({ from, to }) => investedAmount >= from && investedAmount <= to
      )

      if (!tier) throw new Error(`Tier for amount ${investedAmount} not found`)

      return {
        monthly: ((investedAmount / 100) * tier.value) / 12,
        yearly: tier.value,
      }

    case 'life-insurance':
      return {
        monthly:
          ((cost.gestioneSeparataValue / 100 + cost.unitValue / 100) *
            investedAmount) /
          12,
        yearly: cost.gestioneSeparataValue + cost.unitValue,
        gestioneSeparataWeight: cost.gestioneSeparataWeight,
        gestioneSeparataFees: {
          monthly:
            ((cost.gestioneSeparataValue / 100) *
              cost.gestioneSeparataWeight *
              investedAmount) /
            12,
          // NOTE: The actual GS and UL yearly fees should be calculated as
          //  value * weight, but we currently only want to show the 100%
          //  weighted value.
          yearly: cost.gestioneSeparataValue,
        },
        unitLinkedFees: {
          monthly:
            ((cost.unitValue / 100) *
              (1 - cost.gestioneSeparataWeight) *
              investedAmount) /
            12,
          // NOTE: The actual GS and UL yearly fees should be calculated as
          //  value * weight, but we currently only want to show the 100%
          //  weighted value.
          yearly: cost.unitValue,
        },
      }
  }
}

export function getAdditionalFees(
  data: PricingData,
  product: Product,
  amountToInvest: number
): CalculatedAppliedCost | null {
  if (data.additionalCosts && data.additionalCosts[product])
    return calculateCost(amountToInvest, data.additionalCosts[product])

  return null
}

/**
 * This function builds the view model for the calculator's results, given data
 * coming from the input and the pricing data for the currently active country.
 *
 * It calculates:
 *  - Management fees
 *  - Financial instruments fees
 *  - Additional fees
 *  - Yearly total
 *  - Monthly total
 *  - Whether to show the lesser fees tip
 *
 * All fees are returned as both monthly and yearly values, where monthly fees
 * are calculated as a flat amount in currency, and yearly fees are returned as
 * percentages.
 */
export function costsViewModel({
  data,
  product,
  strategy,
  amountToInvest,
  focus,
  isThematics,
}: CostsViewModelInput): CostsViewModel {
  const isESG = focus === 'esg'

  const managementFees = getManagementFees(
    data,
    product,
    strategy,
    amountToInvest
  )

  const financialInstrumentsFees = getFinancialInstrumentsFees(
    data,
    product,
    strategy,
    amountToInvest,
    isESG,
    isThematics
  )

  const shouldShowLesserFeesTipFlag = shouldShowLesserFeesTip(
    data,
    product,
    amountToInvest,
    strategy
  )

  const additionalFees = getAdditionalFees(data, product, amountToInvest)

  return {
    managementFees,
    financialInstrumentsFees,
    additionalFees,
    yearly: managementFees.yearly + financialInstrumentsFees.yearly,
    monthly: managementFees.monthly + financialInstrumentsFees.monthly,
    shouldShowLesserFeesTip: shouldShowLesserFeesTipFlag,
  }
}

function getFinancialInstrumentsFees(
  data: PricingData,
  product: Product,
  strategy: string,
  amountToInvest: number,
  isESG: boolean,
  isThematics: boolean
): {
  yearly: number
  monthly: number
  investmentFundFees: CalculatedAppliedCost
  marketSpreadFees: CalculatedAppliedCost | null
} {
  function getFees(key: string) {
    const investmentFundFees = calculateCost(
      amountToInvest,
      data.investmentFundFees[key]
    )

    // Life-insurance -type products do not have market spread fees.
    const shouldHaveMarketSpreadFees = key !== 'life-insurance'

    const marketSpreadFees = shouldHaveMarketSpreadFees
      ? calculateCost(amountToInvest, data.marketSpreadFees[key])
      : null

    return {
      yearly:
        Number(investmentFundFees.yearly) +
        Number(marketSpreadFees ? marketSpreadFees.yearly : 0),
      monthly:
        Number(investmentFundFees.monthly) +
        Number(marketSpreadFees ? marketSpreadFees.monthly : 0),
      investmentFundFees,
      marketSpreadFees,
    }
  }

  switch (product) {
    case 'isa':
    case 'jisa':
    case 'sipp':
    case 'gia':
      if (isThematics) return getFees('thematics')

      if (isESG) return getFees(strategy + '-esg')

      return getFees(strategy)

    /**
     * The 'sicura e dinamica' life insurance product deserves a dedicated
     * financial instruments fees calculation, as it has a different structure
     * than the other products, where the financial instruments fees are applied
     * only to the unit-linked part of the investment.
     */
    case 'sicura-e-dinamica':
      const fees = getFees('sicura-e-dinamica')

      const monthlyWeight =
        1 -
        (data.managementFees[strategy] as LifeInsuranceCost)
          .gestioneSeparataWeight

      return {
        yearly: fees.yearly,
        monthly: fees.monthly * monthlyWeight,
        investmentFundFees: {
          yearly: fees.investmentFundFees.yearly,
          monthly: fees.investmentFundFees.monthly * monthlyWeight,
        },
        marketSpreadFees: fees.marketSpreadFees
          ? {
              yearly: fees.marketSpreadFees?.yearly,
              monthly: fees.marketSpreadFees?.monthly * monthlyWeight,
            }
          : null,
      }

    // PIP's management costs already include the underlying
    // financial instruments fees.
    case 'pip':
    case 'sicura-100':
      return {
        yearly: 0,
        monthly: 0,
        investmentFundFees: {
          yearly: 0,
          monthly: 0,
        },
        marketSpreadFees: null,
      }
  }
}

export function getManagementFees(
  data: PricingData,
  product: Product,
  strategy: string,
  amountToInvest: number
): CalculatedAppliedCost {
  switch (product) {
    case 'gia':
    case 'isa':
    case 'sipp':
    case 'jisa':
      if (strategy === 'liquidity-plus')
        return calculateCost(
          amountToInvest,
          data.managementFees['liquidity-plus']
        )

      return calculateCost(amountToInvest, data.managementFees[strategy])

    case 'pip':
      return calculateCost(amountToInvest, data.managementFees.pip)

    case 'sicura-e-dinamica':
      return calculateCost(
        amountToInvest,
        data.managementFees[strategy as keyof typeof data.managementFees]
      )

    case 'sicura-100':
      return calculateCost(amountToInvest, data.managementFees['sicura-100'])
  }
}

export function formatYearlyValue(value?: number) {
  if (value === undefined) return 'N/A'

  return Number(value).toFixed(2) + '%'
}

export function formatMonthlyValue(value?: number) {
  if (value === undefined) return 'N/A'

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value)
}

export function productHasThematicsOption(product: Product, strategy: string) {
  return (product === 'gia' || product === 'isa') && strategy === 'active'
}

/**
 * Returns whether the given amount to invest qualifies for lesser fees, by
 * checking if it fits or not in the highest tier for active management fees,
 * and if the current product configuration has tiered pricing.
 *
 * @param data - The pricing data.
 * @param amountToInvest - The amount to invest.
 * @param product - The product.
 * @returns {boolean} Whether the amount to invest qualifies for lesser fees.
 */
export function shouldShowLesserFeesTip(
  data: PricingData,
  product: Product,
  amountToInvest: number,
  strategy: string
): boolean {
  // Life insurance and PIP have no tiered fees.
  if (
    product === 'sicura-e-dinamica' ||
    product === 'sicura-100' ||
    product === 'pip'
  )
    return false

  // Liquidity+ has no tiered fees.
  if (strategy === 'liquidity-plus') return false

  const strategyManagementFees = data.managementFees[strategy]

  if (strategyManagementFees.type !== 'tiered') return false

  return (
    amountToInvest <
    Math.max(...strategyManagementFees.tiers.map(({ from }) => from))
  )
}
