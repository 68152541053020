import styled from 'styled-components'

import { Grid, Text } from 'mf-ui-components'

import { fromBreakpoint } from '../../../../../utils/style/breakpoint'
import { spacing } from '../../../../../utils/ui/spacing'

export const Container = styled(Grid).attrs({
  flow: 'column',
  gap: { sm: 'md', lg: 'xl' },
})`
  padding: ${spacing('xl')};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.tokens.interface.neutral.light};
  background: ${({ theme }) => theme.tokens.space.surface.regular};
`

export const ValueCell = styled(Grid).attrs({
  flow: 'row',
  alignItems: 'center',
  justifyContent: 'end',
})``

export const ResultsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing('4xl')};

  ${fromBreakpoint('md')} {
    display: grid;
    column-gap: ${spacing('sm')};
    grid-template-columns: 1fr 100px 100px;
  }

  ${fromBreakpoint('lg')} {
    column-gap: ${spacing('md')};
    grid-template-columns: 1fr 128px 128px;
  }

  ${fromBreakpoint('xl')} {
    grid-template-columns: 1fr 160px 160px;
  }
`

export const PartialResultsContainer = styled(ResultsRow)`
  width: 100%;
`

export const TotalContainer = styled(ResultsRow)`
  width: calc(100% + 32px);
  background: ${({ theme }) => theme.tokens.space.tertiary.regular};
  padding: ${spacing('md')};
  margin: -${spacing('md')};
  border-radius: 8px;
`

export const SubtotalValue = styled(Text).attrs({
  el: 'span',
  styleName: 'bodyRegular',
})`
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
`

export const PartialValue = styled(Text).attrs({
  el: 'span',
  styleName: 'bodySmall',
  variant: 'secondary',
})`
  font-weight: ${({ theme }) => theme.typography.font.weight.normal};
`

export const TotalValue = styled(Text).attrs({
  el: 'span',
  styleName: 'title6',
})`
  font-family: 'ivypresto-display', 'Playfair Display', serif;
`

export const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 2px;
`

export const ResultValueContainer = styled.div`
  text-align: right;
`
