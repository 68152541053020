import React, { FC, useState } from 'react'

import { Grid } from 'mf-ui-components'

import { FinancialInstrumentsFees } from './FinancialInstrumentsFees'
import { Heading } from './Heading'
import { LifeInsuranceManagementFees } from './LifeInsuranceManagementFees'
import { PipAdministrativeCostsRow } from './PipAdministrativeCosts'
import { Container, Spacer } from './Results.styles'
import { SubTotalResultsRow } from './SubTotalResultsRow'
import { TotalRow } from './TotalRow'
import { useResults } from './useResults'
import { FeesModal } from '../feesModal/FeesModal'
import { useInputState } from '../input/inputStateReducer'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { useIsViewportBelow } from '../../../../../utils/useIsViewportBelow'
import gridTheme from '../../../../../utils/gridTheme'
import { CalculatedAppliedCost } from '../types'
import { LifeInsuranceFees } from './types'

function areLifeInsuranceFees(
  fees: CalculatedAppliedCost | (CalculatedAppliedCost & LifeInsuranceFees)
): fees is CalculatedAppliedCost & LifeInsuranceFees {
  return (
    'gestioneSeparataFees' in fees &&
    'unitLinkedFees' in fees &&
    'gestioneSeparataWeight' in fees
  )
}

export const Results: FC = () => {
  const { state: inputState } = useInputState()
  const results = useResults(inputState)

  const [isFeesModalVisible, setFeesModalVisible] = useState<boolean>(false)
  const [pricingView, setPricingView] = useState<'yearly' | 'monthly'>('yearly')
  const isMobile = useIsViewportBelow(gridTheme.breakpoints.md)
  const displayMode = isMobile ? 'mobile' : 'desktop'

  if (!results) return null

  // PIP is the only product with a flat 10€/year fee, so it requires some
  // custom components to show its fees.
  const productIsPIP = inputState.product === 'pip'

  const productHasFinancialsInstrumentsFees =
    inputState.product !== 'sicura-100' && inputState.product !== 'pip'

  return (
    <Container>
      <FeesModal
        isVisible={isFeesModalVisible}
        setVisible={setFeesModalVisible}
        product={inputState.product}
        strategy={inputState.strategy}
        focus={inputState.focus}
        isThematics={inputState.isThematics}
      />

      <Heading
        isMobile={isMobile}
        pricingView={pricingView}
        setPricingView={setPricingView}
      />

      <Grid flow="column" gap="sm">
        <Grid flow="column" gap="2xs">
          <SubTotalResultsRow
            label={l10nCopy(
              'multiProductPricingCalculator.managementFees.title'
            )}
            fees={results.managementFees}
            displayMode={displayMode}
            pricingView={pricingView}
          />

          {areLifeInsuranceFees(results.managementFees) && (
            <LifeInsuranceManagementFees
              product={inputState.product}
              fees={results.managementFees}
              displayMode={displayMode}
              pricingView={pricingView}
            />
          )}
        </Grid>

        {productIsPIP && (
          <PipAdministrativeCostsRow
            displayMode={displayMode}
            pricingView={pricingView}
          />
        )}

        {inputState.product !== 'pip' && (
          <Grid flow="column" gap="2xs">
            <SubTotalResultsRow
              label={l10nCopy(
                'multiProductPricingCalculator.financialInstrumentsFees.title'
              )}
              fees={results.financialInstrumentsFees}
              displayMode={displayMode}
              pricingView={pricingView}
            />

            {inputState.product !== 'sicura-100' && (
              <FinancialInstrumentsFees
                fees={results.financialInstrumentsFees}
                displayMode={displayMode}
                pricingView={pricingView}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Spacer />

      <TotalRow
        {...results}
        setFeesModalVisible={setFeesModalVisible}
        displayMode={displayMode}
        pricingView={pricingView}
        productIsPIP={productIsPIP}
      />
    </Container>
  )
}
