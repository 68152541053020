import React, { FC } from 'react'

import { FeeBracketsTable, Grid, Text } from 'mf-ui-components'

import { getMarketSpreadFees } from './utils'
import { Product } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { usePricingData } from '../pricingDataProvider'

type Props = {
  product: Product
  strategy: string
  focus: string
  isThematics: boolean
}

export const MarketSpreadFeesTable: FC<Props> = ({
  product,
  strategy,
  focus,
  isThematics,
}) => {
  const pricingData = usePricingData()

  if (product === 'sicura-100') return null

  return (
    <Grid flow="column" gap="2xs">
      <Text styleName="label" el="p">
        {l10nCopy(
          'multiProductPricingCalculator.feesModal.marketSpreadFees.sectionTitle'
        )}
      </Text>
      <FeeBracketsTable
        tableData={[
          {
            description: l10nCopy(
              'multiProductPricingCalculator.feesModal.marketSpreadFees.tableTitle'
            ),
            actualValue: getMarketSpreadFees(
              pricingData,
              product,
              strategy,
              focus,
              isThematics
            ),
          },
        ]}
      />
    </Grid>
  )
}
