import React, {
  createContext,
  useContext,
  useReducer,
  FC,
  ReactNode,
} from 'react'

import { actionsFactory } from './actions'
import { InputState, Action, EnabledProduct } from './types'
import { defaultStateFactory } from './utils'
import { usePricingData } from '../pricingDataProvider'

export function inputStateReducer(
  state: InputState,
  action: Action
): InputState {
  switch (action.type) {
    case 'SET_AMOUNT_TO_INVEST':
    case 'SET_PRODUCT':
    case 'SET_STRATEGY':
    case 'SET_FOCUS':
    case 'SET_THEMATICS':
      return { ...state, ...action.payload }

    default:
      return state
  }
}

export const InputStateContext = createContext({
  state: {} as InputState,
  actions: {} as ReturnType<typeof actionsFactory>,
})

type Props = {
  children: ReactNode
  enabledProduct: EnabledProduct
  initialAmount?: number
}

export const InputStateProvider: FC<Props> = ({
  children,
  /**
   * This attribute indicates that the pricing calculator should disable or
   * limit the product selection to a specific product or group of products.
   */
  enabledProduct = 'all',
  /**
   * This attribute indicates the initial invested amount to be set. If not set,
   * or set to 0, the default minimum investable amount value for the country
   * will be used. 
   */
  initialAmount,
}) => {
  const data = usePricingData()

  const [state, dispatch] = useReducer(
    inputStateReducer,
    { data, enabledProduct, initialAmount },
    defaultStateFactory
  )

  if (!data) return null

  return (
    <InputStateContext.Provider
      value={{ state, actions: actionsFactory(data, state, dispatch) }}
    >
      {children}
    </InputStateContext.Provider>
  )
}

export function useInputState() {
  if (!useContext(InputStateContext))
    throw new Error(
      '`useInputState` must be used within a `InputStateProvider`'
    )

  return useContext(InputStateContext)
}
