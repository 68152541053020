import styled, { DefaultTheme } from 'styled-components'

import { Grid, Icon } from 'mf-ui-components'

import { spacing } from '../../../../utils/ui/spacing'
import { fromBreakpoint } from '../../../../utils/style/breakpoint'

export const Container = styled(Grid).attrs({
  flow: { sm: 'column', lg: 'row' },
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  padding: ${spacing('md')};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.tokens.interface.neutral.light};
  background: ${({ theme }) => theme.tokens.space.surface.regular};
  flex-wrap: wrap;

  ${fromBreakpoint('lg')} {
    padding: ${spacing('xl')};
  }

  ${fromBreakpoint('xxl')} {
    flex-wrap: nowrap;
  }
`

export const ItemsContainer = styled(Grid).attrs({
  flow: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'md',
})`
  flex-wrap: wrap;

  ${fromBreakpoint('lg')} {
    flex-wrap: nowrap;
  }
`

export const Checkmark = styled(Icon).attrs(
  ({ theme }: { theme: DefaultTheme }) => ({
    type: 'icon-check',
    size: 24,
    color: theme.tokens.text.label.positive,
  })
)`
  flex-shrink: 0;
`
