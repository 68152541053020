import React, { FC } from 'react'

import { Grid, Text } from 'mf-ui-components'

import { Checkmark, Container, ItemsContainer } from './Footer.styles'
import { useInputState } from './input/inputStateReducer'
import { l10nCopy } from '../../../../utils/copy/l10nCopy'

export const Footer: FC = () => {
  const { state } = useInputState()

  const items = Array.from({ length: 4 })
    .map((_, index) => index)
    .filter((_, index) => !(state.product === 'sicura-100' && index === 0))

  return (
    <Container>
      <Text styleName="labelSmall" el="p" style={{ whiteSpace: 'nowrap' }}>
        {l10nCopy('multiProductPricingCalculator.footer.title')}
      </Text>

      <div>
        <ItemsContainer>
          {items.map(index => (
            <Grid
              key={index}
              flow="row"
              alignItems="center"
              gap={null}
              style={{ width: 'fit-content' }}
            >
              <Checkmark />
              <Text
                styleName="bodySmall"
                el="p"
                style={{ whiteSpace: 'nowrap' }}
              >
                {l10nCopy(
                  `multiProductPricingCalculator.footer.list.${index + 1}`
                )}
              </Text>
            </Grid>
          ))}
        </ItemsContainer>
      </div>
    </Container>
  )
}
