import React, { FC } from 'react'

import { ChipGroup, FeeBracketsTable, Grid, Text } from 'mf-ui-components'

import { getManagementFees } from './utils'
import { Product } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { useInputState } from '../input/inputStateReducer'
import { usePricingData } from '../pricingDataProvider'

type Props = { product: Product; strategy: string }

export const ManagementFeesTable: FC<Props> = ({ product }) => {
  const pricingData = usePricingData()
  const {
    state: { strategy, availableStrategies },
    actions,
  } = useInputState()

  const data = getManagementFees(pricingData, product, strategy)

  if (!data) return null

  return (
    <Grid flow="column" gap="xl">
      {product === 'sicura-e-dinamica' && (
        <ChipGroup
          chipList={availableStrategies.map((s) => ({
            label: l10nCopy(`multiProductPricingCalculator.strategies.${s}`),
            isSelected: strategy === s,
            onClick: () => actions.setStrategy(s),
          }))}
        />
      )}

      <Grid flow="column" gap="2xs">
        <Text styleName="label" el="p">
          {l10nCopy(
            'multiProductPricingCalculator.feesModal.managementFees.sectionTitle'
          )}
        </Text>
        <FeeBracketsTable
          title={l10nCopy(
            'multiProductPricingCalculator.feesModal.managementFees.tableTitle'
          )}
          tableData={data}
          margin={0}
        />
        {product === 'sicura-e-dinamica' && (
          <>
            <Text el="p" styleName="bodySmall" variant="secondary">
              {l10nCopy(
                'multiProductPricingCalculator.feesModal.managementFees.sicuraEDinamicaDisclaimer'
              )}
            </Text>
          </>
        )}
      </Grid>
    </Grid>
  )
}
