import styled from 'styled-components'

import { fromSize } from 'mf-ui-components'

import { spacing } from '../../../../utils/ui/spacing'

export const Container = styled.div`
  text-align: left;
  display: grid;
  gap: ${spacing('xl')};
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  ${fromSize('md')} {
    grid-template-columns: 360px 1fr;
    grid-template-rows: auto;
  }
`
