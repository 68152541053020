import React, { FC, Dispatch, SetStateAction } from 'react'

import { Grid, Text } from 'mf-ui-components'

import { CostsViewModel, ResultsRowProps } from './types'
import { formatMonthlyValue, formatYearlyValue } from './utils'
import { TotalContainer, TotalValue, ValueCell } from './Results.styles'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'
import { useInputState } from '../input/inputStateReducer'
import { locale } from '../../../../../utils/env'

type Props = Pick<
  CostsViewModel,
  'yearly' | 'monthly' | 'shouldShowLesserFeesTip'
> &
  ResultsRowProps & {
    productIsPIP: boolean
    setFeesModalVisible: Dispatch<SetStateAction<boolean>>
  }

const COUNTRY = process.env.GATSBY_LANG?.toUpperCase()

export const TotalRow: FC<Props> = ({
  shouldShowLesserFeesTip,
  yearly,
  monthly,
  displayMode,
  pricingView,
  productIsPIP,
  setFeesModalVisible,
}) => {
  const { state } = useInputState()

  const lesserFeesTipAmount = formatYearlyValue(
    COUNTRY === 'IT' ? 0.4 : state.strategy === 'fixed' ? 0.25 : 0.35
  )

  return (
    <TotalContainer>
      <Grid flow="column" gap={null}>
        <Text
          el="h2"
          styleName="title4"
          style={{
            fontFamily: "'ivypresto-display', 'Playfair Display', serif",
          }}
        >
          {l10nCopy('multiProductPricingCalculator.totalFees.title')}
        </Text>

        <Text styleName="bodySmall" variant="secondary" el="p">
          {shouldShowLesserFeesTip && (
            <>
              {l10nCopy(
                'multiProductPricingCalculator.managementFees.lesserFeesTip',
                {
                  amount: lesserFeesTipAmount,
                }
              )}
              <br />
            </>
          )}
          <a
            onClick={event => {
              event.preventDefault()

              setFeesModalVisible(true)
            }}
          >
            {l10nCopy(
              'multiProductPricingCalculator.managementFees.lesserFeesLink'
            )}
          </a>
        </Text>
      </Grid>

      {displayMode === 'desktop' ? (
        <>
          <ValueCell>
            <div style={{ textAlign: 'right' }}>
              <Text
                el="span"
                styleName="labelSmall"
                style={{
                  fontFamily: "'ivypresto-display', 'Playfair Display', serif",
                }}
              >
                {l10nCopy('multiProductPricingCalculator.generic.upTo')}
              </Text>{' '}
              <TotalValue>{formatYearlyValue(yearly)}</TotalValue>
              {productIsPIP && (
                <Text el="p" styleName="bodySmall">
                  {l10nCopy(
                    'multiProductPricingCalculator.results.total.pipDisclaimer'
                  )}
                </Text>
              )}
            </div>
          </ValueCell>
          <ValueCell>
            <div>
              <Text
                el="span"
                styleName="labelSmall"
                style={{
                  fontFamily: "'ivypresto-display', 'Playfair Display', serif",
                }}
              >
                {l10nCopy('multiProductPricingCalculator.generic.upTo')}
              </Text>{' '}
              <TotalValue>{formatMonthlyValue(monthly)}</TotalValue>
            </div>
          </ValueCell>
        </>
      ) : (
        <div>
          <ValueCell alignItems="center">
            <div style={{ textAlign: 'right' }}>
              <Text
                el="span"
                styleName="labelSmall"
                style={{
                  fontFamily: "'ivypresto-display', 'Playfair Display', serif",
                }}
              >
                {l10nCopy('multiProductPricingCalculator.generic.upTo')}
              </Text>{' '}
              <TotalValue>
                {pricingView === 'monthly'
                  ? formatMonthlyValue(monthly)
                  : formatYearlyValue(yearly)}
              </TotalValue>
            </div>
          </ValueCell>

          {pricingView === 'yearly' && productIsPIP && (
            <Text
              el="p"
              styleName="bodySmall"
              variant="secondary"
              style={{
                textAlign: 'right',
              }}
            >
              {l10nCopy(
                'multiProductPricingCalculator.results.total.pipDisclaimer'
              )}
            </Text>
          )}
        </div>
      )}
    </TotalContainer>
  )
}
