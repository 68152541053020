import React, { FC } from 'react'

import { Text } from 'mf-ui-components'

import { ResultsRowProps } from './types'
import { formatMonthlyValue } from './utils'
import { IT_PRICING_DATA } from '../data'
import {
  PartialResultsContainer,
  PartialValue,
  ResultValueContainer,
} from './Results.styles'
import { FixedCost } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

type Props = ResultsRowProps

/**
 * This is currently a quick solution to display the PIP administrative costs.
 * It is needed because, given the way we want to display these kind of costs
 * to customers, we just show their value in a raw, textual format, instead of
 * showing their impact relative to the invested amount like we do for
 * other costs.
 */
export const PipAdministrativeCostsRow: FC<Props> = ({
  displayMode,
  pricingView,
}) => {
  const pipAdministrativeCost = (IT_PRICING_DATA.additionalCosts
    ?.pip as FixedCost)?.value

  if (!pipAdministrativeCost) return null

  // NOTE: The cost is formatted as a monthly value, because it is a fixed cost,
  // and not a percentage cost like the other yearly costs we display.
  const formattedCost = formatMonthlyValue(pipAdministrativeCost)

  return (
    <PartialResultsContainer>
      <div>
        <Text el="span" styleName="label">
          {l10nCopy('multiProductPricingCalculator.results.pipAdministrativeCosts')}
        </Text>
      </div>

      {displayMode === 'desktop' ? (
        <>
          <ResultValueContainer>
            <PartialValue>{formattedCost}</PartialValue>
          </ResultValueContainer>
          <ResultValueContainer>
            <PartialValue>
              {l10nCopy('multiProductPricingCalculator.generic.notApplicable')}
            </PartialValue>
          </ResultValueContainer>
        </>
      ) : (
        <ResultValueContainer>
          <PartialValue>
            {pricingView === 'yearly'
              ? formattedCost
              : l10nCopy('multiProductPricingCalculator.generic.notApplicable')}
          </PartialValue>
        </ResultValueContainer>
      )}
    </PartialResultsContainer>
  )
}
