import React, { FC, ReactNode, createContext, useContext } from 'react'

import { DATA } from '../data'
import { PricingData } from '../types'

const PricingDataContext = createContext<PricingData>(DATA)

type Props = { children: ReactNode }

/**
 * Provides the pricing data to the application. This might seem like an
 * overkill in its current form, but it's a good practice to have a single
 * source of truth for the data, as there's a good chance that the data will
 * be fetched from an API in the future, and this would make the transition
 * easier.
 */
export const PricingDataProvider: FC<Props> = ({ children }) => (
  <PricingDataContext.Provider value={DATA}>
    {children}
  </PricingDataContext.Provider>
)

export function usePricingData() {
  if (!useContext(PricingDataContext))
    throw new Error(
      '`usePricingData` must be used within a `PricingDataProvider`'
    )

  return useContext(PricingDataContext)
}
