import React, { FC } from 'react'

import { SegmentedControl, Text } from 'mf-ui-components'

import { Title } from './Heading.styles'
import { PartialResultsContainer, ValueCell } from './Results.styles'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

type Props = {
  isMobile: boolean
  pricingView: 'yearly' | 'monthly'
  setPricingView: (v: 'yearly' | 'monthly') => void
}

export const Heading: FC<Props> = ({
  isMobile,
  pricingView,
  setPricingView,
}) => {
  if (isMobile)
    return (
      <>
        <Title>{l10nCopy('multiProductPricingCalculator.results.title')}</Title>

        <div style={{ width: '100%' }}>
          <SegmentedControl
            variant="secondary"
            tabs={[
              {
                title: l10nCopy('multiProductPricingCalculator.results.yearly'),
              },
              {
                title: l10nCopy(
                  'multiProductPricingCalculator.results.monthly'
                ),
              },
            ]}
            selected={pricingView === 'yearly' ? 0 : 1}
            onClick={(v: number) => {
              if (v === 0) setPricingView('yearly')
              else setPricingView('monthly')
            }}
          />
        </div>
      </>
    )

  return (
    <PartialResultsContainer>
      <Title>{l10nCopy('multiProductPricingCalculator.results.title')}</Title>
      <ValueCell>
        <Text el="span" styleName="bodyRegular" variant="secondary">
          {l10nCopy('multiProductPricingCalculator.results.yearly')}
        </Text>
      </ValueCell>
      <ValueCell>
        <Text el="span" styleName="bodyRegular" variant="secondary">
          {l10nCopy('multiProductPricingCalculator.results.monthly')}
        </Text>
      </ValueCell>
    </PartialResultsContainer>
  )
}
