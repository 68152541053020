import { PricingData } from './types'

export const FOCUS_OPTIONS = [
  { name: 'esg', icon: 'icon-leaf' },
  { name: 'classic', icon: undefined },
] as const

// British data.
const GB_PRICING_DATA: PricingData = {
  minimumInvestment: 500,
  products: [
    {
      name: 'gia',
      minimumInvestment: 5_000,
      strategies: [
        {
          name: 'active',
          variants: ['esg', 'classic'],
          hasThematics: true,
        },
        {
          name: 'fixed',
          variants: ['esg', 'classic'],
        },
        {
          name: 'liquidity-plus',
        },
      ],
    },
    {
      name: 'isa',
      minimumInvestment: 5_000,
      strategies: [
        {
          name: 'active',
          variants: ['esg', 'classic'],
          hasThematics: true,
        },
        {
          name: 'fixed',
          variants: ['esg', 'classic'],
        },
        {
          name: 'liquidity-plus',
        },
      ],
    },
    {
      name: 'jisa',
      minimumInvestment: 500,
      strategies: [
        {
          name: 'active',
          variants: ['esg', 'classic'],
        },
        {
          name: 'fixed',
          variants: ['esg', 'classic'],
        },
        {
          name: 'liquidity-plus',
        },
      ],
    },
    {
      name: 'sipp',
      minimumInvestment: 5_000,
      strategies: [
        {
          name: 'active',
          variants: ['esg', 'classic'],
        },
        {
          name: 'fixed',
          variants: ['esg', 'classic'],
        },
        {
          name: 'liquidity-plus',
        },
      ],
    },
  ],
  managementFees: {
    'liquidity-plus': {
      type: 'percentage',
      value: 0.3,
    },
    active: {
      type: 'tiered',
      tiers: [
        {
          from: 0,
          to: 499,
          value: 0,
        },
        {
          from: 500,
          to: 9_999,
          value: 0.75,
        },
        {
          from: 10_000,
          to: 19_999,
          value: 0.7,
        },
        {
          from: 20_000,
          to: 49_999,
          value: 0.65,
        },
        {
          from: 50_000,
          to: 99_999,
          value: 0.6,
        },
        {
          from: 100_000,
          to: 249_999,
          value: 0.45,
        },
        {
          from: 250_000,
          to: 499_999,
          value: 0.4,
        },
        {
          from: 500_000,
          to: Infinity,
          value: 0.35,
        },
      ],
    },
    fixed: {
      type: 'tiered',
      tiers: [
        {
          from: 0,
          to: 499,
          value: 0,
        },
        {
          from: 500,
          to: 99_999,
          value: 0.45,
        },
        {
          from: 100_000,
          to: 249_999,
          value: 0.35,
        },
        {
          from: 250_000,
          to: 499_999,
          value: 0.3,
        },
        {
          from: 500_000,
          to: Infinity,
          value: 0.25,
        },
      ],
    },
  },
  investmentFundFees: {
    active: {
      type: 'percentage',
      value: 0.2,
    },
    fixed: {
      type: 'percentage',
      value: 0.15,
    },
    'active-esg': {
      type: 'percentage',
      value: 0.21,
    },
    'fixed-esg': {
      type: 'percentage',
      value: 0.2,
    },
    thematics: {
      type: 'percentage',
      // Costs are actually 45%, but only applied to 20% of the investment,
      // while the remaining 80% has a 20% cost like for active management.
      // 26% comes from: (20 * 0.47 + 80 * 0.2).
      value: 0.26,
    },
    'liquidity-plus': {
      type: 'percentage',
      value: 0.1,
    },
  },
  marketSpreadFees: {
    active: {
      type: 'percentage',
      value: 0.1,
    },
    fixed: {
      type: 'percentage',
      value: 0.02,
    },
    'active-esg': {
      type: 'percentage',
      value: 0.1,
    },
    'fixed-esg': {
      type: 'percentage',
      value: 0.02,
    },
    thematics: {
      type: 'percentage',
      value: 0.3,
    },
    'liquidity-plus': {
      type: 'percentage',
      value: 0,
    },
  },
}

// Italian data.
export const IT_PRICING_DATA: PricingData = {
  minimumInvestment: 5_000,
  products: [
    {
      name: 'gia',
      strategies: [
        { name: 'active', variants: ['esg', 'classic'] },
        { name: 'liquidity-plus' },
      ],
      minimumInvestment: 5_000,
    },
    {
      name: 'pip',
      strategies: [{ name: 'active', variants: ['esg'] }],
      minimumInvestment: 100,
    },
    {
      name: 'sicura-e-dinamica',
      strategies: [
        { name: 'linea-a' },
        { name: 'linea-b' },
        { name: 'linea-c' },
        { name: 'linea-d' },
        { name: 'linea-e' },
      ],
      minimumInvestment: 15_000,
    },
    {
      name: 'sicura-100',
      strategies: [{ name: 'sicura-100' }],
      minimumInvestment: 15_000,
    },
  ],
  managementFees: {
    'liquidity-plus': {
      type: 'percentage',
      value: 0.25,
    },
    active: {
      type: 'tiered',
      tiers: [
        {
          from: 0,
          to: 4_999,
          value: 0,
        },
        {
          from: 5_000,
          to: 19_999,
          value: 1,
        },
        {
          from: 20_000,
          to: 199_999,
          value: 0.75,
        },
        {
          from: 200_000,
          to: 499_999,
          value: 0.5,
        },
        {
          from: 500_000,
          to: Infinity,
          value: 0.4,
        },
      ],
    },
    pip: {
      type: 'percentage',
      value: 1.25,
    },
    'sicura-100': {
      type: 'life-insurance',
      gestioneSeparataValue: 1.5,
      gestioneSeparataWeight: 1,
      unitValue: 0,
    },
    'linea-a': {
      type: 'life-insurance',
      gestioneSeparataValue: 0.75,
      gestioneSeparataWeight: 0.5,
      unitValue: 0.58,
    },
    'linea-b': {
      type: 'life-insurance',
      gestioneSeparataValue: 0.75,
      gestioneSeparataWeight: 0.5,
      unitValue: 0.58,
    },
    'linea-c': {
      type: 'life-insurance',
      gestioneSeparataValue: 0.6,
      gestioneSeparataWeight: 0.4,
      unitValue: 0.69,
    },
    'linea-d': {
      type: 'life-insurance',
      gestioneSeparataValue: 0.45,
      gestioneSeparataWeight: 0.3,
      unitValue: 0.81,
    },
    'linea-e': {
      type: 'life-insurance',
      gestioneSeparataValue: 0.3,
      gestioneSeparataWeight: 0.2,
      unitValue: 0.92,
    },
  },
  investmentFundFees: {
    active: {
      type: 'percentage',
      value: 0.2,
    },
    'active-esg': {
      type: 'percentage',
      value: 0.2,
    },
    'liquidity-plus': {
      type: 'percentage',
      value: 0.1,
    },
    thematics: {
      type: 'percentage',
      // Costs are actually 45%, but only applied to 20% of the investment,
      // while the remaining 80% has a 20% cost like for active management.
      // 25% comes from: (20 * 0.45 + 80 * 0.2).
      value: 0.25,
    },
    'sicura-e-dinamica': {
      type: 'percentage',
      value: 0.18,
    },
  },
  marketSpreadFees: {
    active: {
      type: 'percentage',
      value: 0.08,
    },
    'active-esg': {
      type: 'percentage',
      value: 0.08,
    },
    'liquidity-plus': {
      type: 'percentage',
      value: 0,
    },
    thematics: {
      type: 'percentage',
      value: 0.2,
    },
    'sicura-e-dinamica': {
      type: 'percentage',
      value: 0.08,
    },
  },
  additionalCosts: {
    pip: {
      type: 'fixed',
      value: 10,
      interval: 'year',
    },
  },
}

export const DATA =
  process.env.GATSBY_LANG?.toUpperCase() === 'IT'
    ? IT_PRICING_DATA
    : GB_PRICING_DATA
