import React, { FC, useState } from 'react'

import { Icon, Modal, Text } from 'mf-ui-components'

import {
  PartialResultsContainer,
  PartialValue,
  ResultValueContainer,
} from './Results.styles'
import { ResultsRowProps } from './types'
import { formatMonthlyValue, formatYearlyValue } from './utils'
import { CalculatedAppliedCost } from '../types'
import { l10nCopy } from '../../../../../utils/copy/l10nCopy'

type Props = {
  label: string
  modalText?: string
  fees: CalculatedAppliedCost
} & ResultsRowProps

export const PartialResultsRow: FC<Props> = ({
  label,
  modalText,
  fees,
  displayMode = 'desktop',
  pricingView,
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  return (
    <>
      <PartialResultsContainer>
        <div>
          <Text el="span" styleName="bodySmall" variant="secondary">
            {label}{' '}
            {!!modalText?.length && (
              <Icon
                color="Silver"
                type="icon-info-circle"
                size="16"
                style={{ transform: 'translateY(4px)', cursor: 'pointer' }}
                onClick={() => setModalVisible(true)}
              />
            )}
          </Text>
        </div>

        {displayMode === 'desktop' ? (
          <>
            <ResultValueContainer>
              <Text el="span" styleName="bodySmall" variant="secondary">
                {formatYearlyValue(fees.yearly)}
              </Text>
            </ResultValueContainer>
            <ResultValueContainer>
              <Text el="span" styleName="bodySmall" variant="secondary">
                {formatMonthlyValue(fees.monthly)}
              </Text>
            </ResultValueContainer>{' '}
          </>
        ) : (
          <ResultValueContainer>
            <PartialValue>
              {pricingView === 'yearly'
                ? formatYearlyValue(fees[pricingView])
                : formatMonthlyValue(fees[pricingView])}
            </PartialValue>
          </ResultValueContainer>
        )}
      </PartialResultsContainer>

      <Modal
        isVisible={isModalVisible}
        size="medium"
        modalContentList={[
          {
            body: (
              <Text el="p" styleName="bodyRegular">
                {modalText}
              </Text>
            ),
            header: {
              title: l10nCopy(
                'multiProductPricingCalculator.marketSpreadModal.title'
              ),
            },
            footer: {
              primaryButton: {
                text: l10nCopy('generic.close'),
                onClick: () => setModalVisible(false),
              },
            },
          },
        ]}
      />
    </>
  )
}
